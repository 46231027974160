@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #ffffff;
  min-height: 100vh;
  color: #000;
}

.dice-container {
  perspective: 1000px;
}

.dice-hover {
  transition: transform 0.2s;
}

.dice-hover:hover {
  transform: scale(1.05);
}

@import url("https://fonts.googleapis.com/css2?family=VT323&display=swap");

.vt323-regular {
  font-family: "VT323", monospace;
  font-weight: 400;
  font-style: normal;
}

/* Tambahan style untuk cards */
.glass-card {
  background: rgba(0, 0, 0, 0.02);
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
}

/* Animasi untuk timer */
.timer-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Overscroll behavior untuk previous dice */
.overscroll-bounce {
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  scroll-snap-type: x mandatory;
  scroll-padding: 0.5rem;
}

.scroll-item {
  scroll-snap-align: center;
}

/* Styling untuk winner cards */
.winner-card {
  transition: all 0.2s ease-in-out;
}

.winner-card:hover {
  transform: translateX(4px);
}

/* Subtle gradient animation */
@keyframes gradient-shift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Gradient fade animation */
@keyframes gradient-fade {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.5;
  }
}

.gradient-fade {
  animation: gradient-fade 2s ease-in-out infinite;
}

@keyframes nudge {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(4px);
  }
}

.animate-nudge {
  animation: nudge 1s ease-in-out infinite;
}

/* 3D Dice Styles */
.dice-3d-wrapper {
  width: 90px;
  height: 90px;
  margin: 0 auto;
}

.dice-3d {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.1s ease-out;
}

.dice-3d .sides {
  background-color: white;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.dice-3d .dot {
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #333;
  transform: translate(-50%, -50%);
}

/* Dot positions */
.side-1 .dot-1 {
  top: 50%;
  left: 50%;
}

.side-2 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-2 .dot-2 {
  top: 75%;
  left: 75%;
}

.side-3 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-3 .dot-2 {
  top: 75%;
  left: 75%;
}
.side-3 .dot-3 {
  top: 50%;
  left: 50%;
}

.side-4 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-4 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-4 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-4 .dot-4 {
  top: 75%;
  left: 75%;
}

.side-5 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-5 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-5 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-5 .dot-4 {
  top: 75%;
  left: 75%;
}
.side-5 .dot-5 {
  top: 50%;
  left: 50%;
}

.side-6 .dot-1 {
  top: 25%;
  left: 25%;
}
.side-6 .dot-2 {
  top: 25%;
  left: 75%;
}
.side-6 .dot-3 {
  top: 75%;
  left: 25%;
}
.side-6 .dot-4 {
  top: 75%;
  left: 75%;
}
.side-6 .dot-5 {
  top: 50%;
  left: 25%;
}
.side-6 .dot-6 {
  top: 50%;
  left: 75%;
}

/* Side transforms */
.dice-3d .side-1 {
  transform: translateZ(45px);
}
.dice-3d .side-2 {
  transform: rotateX(-180deg) translateZ(45px);
}
.dice-3d .side-3 {
  transform: rotateY(90deg) translateZ(45px);
}
.dice-3d .side-4 {
  transform: rotateY(-90deg) translateZ(45px);
}
.dice-3d .side-5 {
  transform: rotateX(90deg) translateZ(45px);
}
.dice-3d .side-6 {
  transform: rotateX(-90deg) translateZ(45px);
}

/* Dice rotations */
.dice-3d[data-side="1"] {
  transform: rotateX(360deg) rotateY(360deg) rotateZ(720deg);
}
.dice-3d[data-side="2"] {
  transform: rotateX(360deg) rotateY(540deg) rotateZ(720deg);
}
.dice-3d[data-side="3"] {
  transform: rotateX(360deg) rotateY(630deg) rotateZ(720deg);
}
.dice-3d[data-side="4"] {
  transform: rotateX(360deg) rotateY(450deg) rotateZ(720deg);
}
.dice-3d[data-side="5"] {
  transform: rotateX(630deg) rotateY(360deg) rotateZ(720deg);
}
.dice-3d[data-side="6"] {
  transform: rotateX(450deg) rotateY(360deg) rotateZ(720deg);
}

/* ReRoll animations */
.dice-3d.reRoll[data-side="1"] {
  transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
}
.dice-3d.reRoll[data-side="2"] {
  transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg);
}
.dice-3d.reRoll[data-side="3"] {
  transform: rotateX(0deg) rotateY(270deg) rotateZ(0deg);
}
.dice-3d.reRoll[data-side="4"] {
  transform: rotateX(0deg) rotateY(90deg) rotateZ(0deg);
}
.dice-3d.reRoll[data-side="5"] {
  transform: rotateX(270deg) rotateY(0deg) rotateZ(0deg);
}
.dice-3d.reRoll[data-side="6"] {
  transform: rotateX(90deg) rotateY(0deg) rotateZ(0deg);
}

.dice-3d.reRoll {
  transition: transform 1s ease-out;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.typing-cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: currentColor;
  margin-left: 2px;
  animation: blink 1s step-end infinite;
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.animate-slide-up {
  animation: slide-up 0.3s ease-out forwards;
}

/* Styling untuk drag handle */
.drag-handle {
  transition: all 0.2s ease;
  touch-action: none;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

.drag-handle:hover {
  cursor: grab;
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.5);
}

.drag-handle:active {
  cursor: grabbing;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.7);
}

/* Prevent text selection while dragging */
.dragging {
  user-select: none;
  touch-action: none;
}

.non-draggable {
  user-select: none;
  pointer-events: none;
}

/* Animasi untuk floating button */
@keyframes pulse-slow {
  0%,
  100% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0.95;
    transform: scale(1.02);
  }
}

@keyframes bounce-gentle {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2px);
  }
}

@keyframes bounce-x {
  0%,
  100% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(2px);
  }
}

.animate-pulse-slow {
  animation: pulse-slow 3s ease-in-out infinite;
}

.animate-bounce-gentle {
  animation: bounce-gentle 2s ease-in-out infinite;
}

.animate-bounce-x {
  animation: bounce-x 1s ease-in-out infinite;
}

/* Neon glow animation */
@keyframes neon-glow {
  0%,
  100% {
    box-shadow: 0 0 5px rgba(59, 130, 246, 0.5), 0 0 10px rgba(59, 130, 246, 0.5), 0 0 15px rgba(147, 51, 234, 0.5);
  }
  50% {
    box-shadow: 0 0 10px rgba(59, 130, 246, 0.8), 0 0 20px rgba(59, 130, 246, 0.8), 0 0 30px rgba(147, 51, 234, 0.8);
  }
}

.animate-neon {
  animation: neon-glow 2s ease-in-out infinite;
}
